import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function ProfilePic(props) {
	switch (props.name) {
		case 'Ferhat Beyaz': return <StaticImage src={'../images/avatar/ferhat.png'} alt={'Ferhat Beyaz'} className="rounded-full" />;
		default: return null
	}
	
}
