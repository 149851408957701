import * as React from "react"
import { Link, graphql } from "gatsby"

import PageLayout from "./page-layout"
import ArticleTile from "../components/article-tile"
import MarketingCta from "../components/marketing-cta"
import Seo from "../components/seo"
import ProfilePic from "../components/profile-pic"

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

function bgColor(type) {
	switch (type) {
		case 'exploo': return 'bg-gradient-to-tr from-pink-500 to-red-500'
		case 'lavender': return 'bg-gradient-to-r from-indigo-400 to-purple-600'
		case 'oceanic': return 'bg-gradient-to-r from-blue-700 via-purple-600 to-blue-700'
		case 'hyper': return 'bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500'
		case 'gotham': return 'bg-gradient-to-t from-gray-600 via-gray-900 to-black'
		case 'valentine': return 'bg-gradient-to-t from-red-300 to-red-600'
		case 'acadia': return 'bg-gradient-to-br from-red-800 via-red-500 to-yellow-500'
		case 'burning-sunrise': return 'bg-gradient-to-r from-yellow-600 to-red-600'
		case 'flare': return 'bg-gradient-to-br from-orange-600 to-orange-400'
		case 'sublime': return 'bg-gradient-to-r from-rose-400 via-fuchsia-500 to-indigo-500'
		case 'passion': return 'bg-gradient-to-r from-rose-500 via-red-400 to-red-500'
		case 'pink-neon': return 'bg-gradient-to-r from-fuchsia-600 to-pink-600'
		case 'emerald': return 'bg-gradient-to-r from-emerald-500 to-lime-600'
		case 'blue-coral': return 'bg-gradient-to-r from-blue-400 to-emerald-400'
		case 'orange-coral': return 'bg-gradient-to-r from-orange-400 to-rose-400'
		case 'horizon': return 'bg-gradient-to-b from-orange-500 to-yellow-300'
		case 'messanger': return 'bg-gradient-to-r from-sky-400 to-blue-500'
		case 'purple-haze': return 'bg-gradient-to-r from-purple-800 via-violet-900 to-purple-800'
		case 'video': return 'bg-gradient-to-tr from-red-500 to-red-800'
		case 'solid-blue': return 'bg-gradient-to-t from-blue-500 to-blue-700'
		case 'dark-blue': return 'bg-gradient-to-t from-indigo-900 to-indigo-700'
		case 'space': return 'bg-gradient-to-t from-gray-900 to-gray-600'
		default: return 'bg-indigo-500'
	}
}

const PostLayout = ({ data, location }) => {
	const post = data.markdownRemark
	const siteTitle = data.site.siteMetadata?.title || `Title`
	const { previous, next } = data
	
	return (
		<PageLayout location={location} title={siteTitle}>
			<Seo title={post.frontmatter.title} description={post.frontmatter.description || post.excerpt} />
			
			<article itemScope itemType="http://schema.org/Article">
				<header className={classNames('mb-24 md:mb-60 relative h-[24rem] md:h-[28rem]', bgColor(post.frontmatter.bgType))}>
					<div className="pb-24 max-w-prose flex flex-col justify-center w-full h-full m-auto text-center text-lg px-4 z-20">
						<h1 className="mt-2 text-3xl md:text-4xl font-semibold md:leading-snug tracking-wide text-gray-100">{post.frontmatter.title}</h1>
						<div className="mt-4 mx-auto text-left flex">
							<div className="flex-shrink-0">
								<span className="sr-only">{post.frontmatter.authorName}</span>
								<div className="h-8 w-8"><ProfilePic name={post.frontmatter.authorName} /></div>
							</div>
							<div className="ml-2 flex items-center space-x-1 text-xs md:text-sm text-gray-100">
								by {post.frontmatter.authorName}
								<span aria-hidden="true" className="ml-1">&middot;</span>
								<time>{post.frontmatter.date}</time>
								<span aria-hidden="true" className="hidden md:block ml-1">&middot;</span>
								<span className="hidden md:block">{post.frontmatter.readingTime} read</span>
							</div>
						</div>
					</div>
					<div className="px-4">
						<img src={post.frontmatter.imageUrl ? post.frontmatter.imageUrl.childImageSharp.gatsbyImageData.images.fallback.src: null} alt={post.frontmatter.title} className="-mt-28 md:-mt-32 mx-auto h-[12rem] md:h-[20rem] w-[36rem] rounded-md object-cover bg-white" />
					</div>
				</header>
			
				<section dangerouslySetInnerHTML={{ __html: post.html }} itemProp="articleBody" className="mt-6 px-4 prose prose-indigo prose-lg text-gray-500 mx-auto" />
			</article>

			<nav className="mt-16 mb-8 max-w-4xl text-gray-500 mx-auto px-4">
				<ul className={classNames('list-none grid gap-x-24 lg:max-w-none', previous && next ? 'lg:grid-cols-2': 'lg:grid-cols-1')}>
					<li className="flex">
						{previous && (
							<div key={previous.fields.slug} className="space-y-4 max-w-md mx-auto">
								<Link to={previous.fields.slug}>
									<h3 className="text-md font-medium text-gray-600 text-center">&larr; Previous Post</h3>
								</Link>
								<ArticleTile
									title={previous.frontmatter.title}
									description={previous.frontmatter.description}
									excerpt={previous.excerpt}
									date={previous.frontmatter.date}
									slug={previous.fields.slug}
									imageUrl={previous.frontmatter.imageUrl ? previous.frontmatter.imageUrl.childImageSharp.gatsbyImageData.images.fallback.src: null}
									authorName={previous.frontmatter.authorName}
									authorUrl={previous.frontmatter.authorUrl}
									authorImageUrl={previous.frontmatter.authorImageUrl}
									readingTime={previous.frontmatter.readingTime}
								/>
							</div>
						)}
					</li>
					<li>
						{next && (
							<div key={next.fields.slug} className="space-y-4 max-w-md mx-auto">
								<Link to={next.fields.slug}>
									<h3 className="text-md font-medium text-gray-600 text-center">Next Post &rarr;</h3>
								</Link>
								<ArticleTile
									title={next.frontmatter.title}
									description={next.frontmatter.description}
									excerpt={next.excerpt}
									date={next.frontmatter.date}
									slug={next.fields.slug}
									imageUrl={next.frontmatter.imageUrl ? next.frontmatter.imageUrl.childImageSharp.gatsbyImageData.images.fallback.src: null}
									authorName={next.frontmatter.authorName}
									authorUrl={next.frontmatter.authorUrl}
									authorImageUrl={next.frontmatter.authorImageUrl}
									readingTime={next.frontmatter.readingTime}
								/>
							</div>
						)}
					</li>
				</ul>
			</nav>
			
			<MarketingCta />
		</PageLayout>
	)
}

export default PostLayout

export const pageQuery = graphql`
	query BlogPostBySlug(
		$id: String!
		$previousPostId: String
		$nextPostId: String
	) {
		site {
			siteMetadata {
				title
				siteUrl
			}
		}
		markdownRemark(id: { eq: $id }) {
			id
			excerpt(pruneLength: 160)
			html
			fields {
				slug
			}
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				title
				description
				imageUrl {
					childImageSharp {
						gatsbyImageData(webpOptions: {quality: 100}, formats: WEBP)
					}
				}
				authorName
				authorImageUrl
				readingTime
				bgType
			}
		}
		previous: markdownRemark(id: { eq: $previousPostId }) {
			excerpt
			fields {
				slug
			}
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				title
				description
				imageUrl {
					childImageSharp {
						gatsbyImageData(webpOptions: {quality: 100}, formats: WEBP)
					}
				}
				authorName
				authorImageUrl
				readingTime
			}
		}
		next: markdownRemark(id: { eq: $nextPostId }) {
			excerpt
			fields {
				slug
			}
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				title
				description
				imageUrl {
					childImageSharp {
						gatsbyImageData(webpOptions: {quality: 100}, formats: WEBP)
					}
				}
				authorName
				authorImageUrl
				readingTime
			}
		}
	}
`
