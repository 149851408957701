import * as React from "react"
import { Link } from "gatsby"

import ProfilePic from "../components/profile-pic"

export default function ArticleTile(props) {
	return (
		<article key={props.title} itemScope itemType="http://schema.org/Article" className="flex flex-col rounded-lg shadow-lg overflow-hidden">
			<div className="flex-shrink-0">
				<Link to={props.slug} itemProp="url" className="block">
					<img src={props.imageUrl} alt={props.title} className="h-48 w-full object-cover rounded-t-lg" />
				</Link>
			</div>
			<div className="flex-1 bg-white p-6 flex flex-col justify-between">
				<div className="flex-1">
					<Link to={props.slug} itemProp="url" className="block mt-2">
						<p className="text-xl font-semibold text-gray-900">{props.title}</p>
						<p className="mt-3 text-base text-gray-500">{props.description}</p>
					</Link>
				</div>
				<div className="mt-6 flex items-center">
					<div className="flex-shrink-0">
						<span className="sr-only">{props.authorName}</span>
						<div className="h-10 w-10"><ProfilePic name={props.authorName} /></div>
					</div>
					<div className="ml-3">
						<p className="text-sm font-medium text-gray-900">
							{props.authorName}
						</p>
						<div className="flex space-x-1 text-sm text-gray-500">
							<time>{props.date}</time>
							<span aria-hidden="true">&middot;</span>
							<span>{props.readingTime} read</span>
						</div>
					</div>
				</div>
			</div>
		</article>
	)
}
